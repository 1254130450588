import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment/moment";
import { useParams } from "react-router-dom";
import { Image, Skeleton, Tag, Row, Col } from "antd";
import { FaBlog } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { trailing_key, API_BLOG_URL, CLIENT_SITE_URL } from "../../constants";
import { renderAsP } from "../portal/chunk-page";
import { getFirstNSentences, stripTags } from "../../utils/utils";
import { ShareFBButton, ShareWSButton, ShareXButton } from "../../utils/utils";
import PageFooter from "../../layout/footer";
import PageHeader from "../../layout/header";
import SectionImage from "../../components/visual/section-image";

import background from "../../assets/background-ad.jpg";

const BlogPost = () => {
  const { id } = useParams(); // Obtener el ID de la entrada de blog desde la URL
  const [blogPost, setBlogPost] = useState(null);

  // Función para obtener una entrada de blog individual
  const fetchBlogPost = async () => {
    try {
      const uri = `${API_BLOG_URL}/blogpost/${id}/${trailing_key}`;
      const response = await axios.get(uri);
      setBlogPost(response.data);
    } catch (error) {
      console.error("Error fetching blog post:", error);
    }
  };

  useEffect(() => {
    fetchBlogPost();
  }, [id]);

  if (!blogPost)
    return (
      <div>
        <Skeleton />
      </div>
    );

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={CLIENT_SITE_URL + "/blog/" + blogPost.id + "/" + blogPost.slug}
        />
        <meta
          name="description"
          content={getFirstNSentences(stripTags(blogPost?.text), 1)}
        />
        <title>{blogPost?.title}</title>
        <div id="fb-root"></div>
        <script
          async
          defer
          crossorigin="anonymous"
          src="https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v20.0&appId=201687056319541"
          nonce="jE9wy33w"
        ></script>
        <style type="text/css">{".ant-image{width: 100%}"}</style>
      </Helmet>
      <PageHeader />
      <SectionImage title={blogPost.title} />
      <div className="col-md-8 offset-md-2 col-xs-12 text-page mt-4 m-px-2">
        <Row gutter={[8, 8]} className="mt-2">
          <Col md={24} xs={24}>
            {blogPost && blogPost.image ? (
              <div style={{ width: "100%", margin: "0 auto" }}>
                <Image
                  src={blogPost.image_url}
                  onError={(e) => {
                    e.target.src = { background };
                  }}
                  alt={blogPost.image_title}
                  style={{
                    display: "block",
                    width: "100%",
                    maxHeight: "400px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  preview={false}
                />
              </div>
            ) : (
              <div
                className="py-4 text-center"
                style={{ backgroundColor: "#efefef" }}
              >
                <FaBlog
                  size="195px"
                  style={{
                    color: "#aaaaaa",
                  }}
                />
              </div>
            )}
          </Col>
        </Row>
        <Row gutter={[8, 8]} className="mt-2">
          <Col md={24} xs={24}>
            <p className="pull-right">{blogPost.image_title}</p>
          </Col>
        </Row>
        <Row gutter={[8, 8]} className="mt-2">
          <Col md={24} xs={24}>
            {renderAsP(blogPost.text, "blog-lead")}
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col md={24} xs={24}>
            <div className="pull-right mb-4 me-4">
              <i>
                Publicado el{" "}
                {blogPost?.created
                  ? moment(blogPost?.created).format("D/M/YY")
                  : ""}
              </i>
            </div>
          </Col>
        </Row>
        <Row gutter={[8, 8]} className="mt-2">
          <Col md={24} xs={24}>
            <div className="me-3">
              {Object.values(blogPost.tags).map((tag) => (
                <Tag color="rgba(246, 9, 9, 0.8)" className="ms-1- pull-right">
                  <strong key={tag.id}>{tag.name}</strong>
                </Tag>
              ))}
            </div>
          </Col>
        </Row>
        <Row gutter={[8, 8]} className="mt-4 mb-4">
          <Col md={24} xs={24}>
            <ShareWSButton
              className="pull-right me-1"
              color="rgb(0,168,132)"
              size="32"
              message={
                "¡Míralo en en Destino Docente! " +
                `${blogPost.title}` +
                ` https://destinodocente.es/blog/${blogPost?.id}/${blogPost.slug}`
              }
            />
            <ShareFBButton
              className="pull-right"
              color="#1877f2"
              size="32"
              url={`https://destinodocente.es/blog/${blogPost?.id}/${blogPost.slug}`}
              message={`¡Míralo en Destino Docente! ${blogPost.title} `}
            />
            <ShareXButton
              className="pull-right"
              size="32"
              url={`https://destinodocente.es/blog/${blogPost?.id}/${blogPost.slug}`}
              message={`¡Míralo en Destino Docente! ${blogPost.title} `}
              hashtags={"destinodocente,SIPRI"}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col md={24} xs={24}>
            <div className="text-center my-4 py-4">
              <div
                className="fb-comments"
                data-href="https://destinodocente.es/blog"
                data-width="100%"
                data-numposts="5"
                data-mobile="true"
              ></div>
            </div>
          </Col>
        </Row>
      </div>
      <PageFooter />
    </>
  );
};

export default BlogPost;
