import { LoadingOutlined } from "@ant-design/icons";
import { FaTaxi, FaPlus } from "react-icons/fa";
import { ReactComponent as TaxiIcon } from "../../assets/taxi-plus.svg";
import { ReactComponent as XIcon } from "../../assets/x-icon.svg";
import { ReactComponent as BuildingIcon } from "../../assets/ad-plus.svg";
import { ReactComponent as ResetIcon } from "../../assets/reset-filters.svg";

export const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 48,
    }}
    spin
  />
);

export const TravelPlusIcon = (props) => {
  return (
    <>
      <TaxiIcon width={props.iconSize ? props.iconSize : "18"} />
    </>
  );
};

export const FaXIcon = (props) => {
  return (
    <>
      <XIcon width={props.iconSize ? props.iconSize : "20"} />
    </>
  );
};

export const AdPlusIcon = (props) => {
  return (
    <>
      <BuildingIcon width={props.iconSize ? props.iconSize : "18"} color={props.color} />
    </>
  );
};


export const ResetFilterIcon = (props) => {
  return (
    <>
      <ResetIcon width={props.iconSize ? props.iconSize : "18"} />
    </>
  );
};

// no se usa
export const TravelPlusIconI = (props) => (
  <div className="icon-container-square">
    <FaTaxi
      className="fa-taxi-icon"
      size={props.iconSize ? props.iconSize : ""}
    />
    <FaPlus className="fa-plus-icon" />
  </div>
);
