import React from "react";

import { Layout } from "antd";
import logoGray from "../assets/logo_gray.png";

const { Footer } = Layout;

const PageFooter = (props) => {
  return (
    <>
      <Footer className="footer">
        <div className="row" style={{ margin: 0 }}>
          <div className="col-md-4 col-xs-12">
            <ul>
              <li>
                <a href="/terminos-de-uso" className="me-1-">
                  Términos de uso
                </a>
              </li>
              <li>
                <a href="/privacidad" className="mx-1-">
                  Privacidad
                </a>
              </li>
              <li>
                <a href="/sobre-nosotros" className="ms-1-">
                  Sobre nosotros
                </a>
              </li>
              <li>
                <a href="/fuentes-de-datos">Fuentes de datos</a>
              </li>
              <li>
                <a href="/contacto" className="mx-1-">
                  Contacto
                </a>
              </li>
              <li>
                <a href="/blog" className="mx-1-">
                  Blog
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 col-xs-12">
            {!!props.extraLinks && (
              <ul>
                <li>
                  <a href="/centros/lista/1/Andalucía">
                    Centros docentes de Andalucía
                  </a>
                </li>
                <li>
                  <a href="/centros/lista/5/Aragón">
                    Centros docentes de Aragón
                  </a>
                </li>
                <li>
                  <a href="/centros/lista/9/Castilla-La Mancha">
                    Centros docentes de Castilla-La Mancha
                  </a>
                </li>
                <li>
                  <a href="/centros/lista/4/Castilla y León">
                    Centros docentes de Castilla y León
                  </a>
                </li>
                <li>
                  <a href="/centros/lista/3/Comunidad de Madrid">
                    Centros docentes de Comunidad de Madrid
                  </a>
                </li>
                <li>
                  <a href="/centros/lista/7/Comunidad Valenciana">
                    Centros docentes de Comunidad Valenciana
                  </a>
                </li>
                <li>
                  <a href="/centros/lista/8/Galicia">
                    Centros docentes de Galicia
                  </a>
                </li>
                <li>
                  <a href="/centros/lista/2/Región de Murcia">
                    Centros docentes de Región de Murcia
                  </a>
                </li>
              </ul>
            )}
          </div>

          <div className="col-md-4 col-xs-12 text-center">
            <a
              href="https://www.facebook.com/profile.php?id=100092298994960"
              target="_blank"
              rel="noreferrer"
              style={{ fontSize: "18px" }}
            >
              Síguenos en{"  "}
              <i
                className="fa-brands fa-square-facebook ms-2"
                aria-hidden="true"
                style={{ fontSize: "22px" }}
              ></i>
            </a>
          </div>
        </div>

        <div className="text-center text-white mt-3">
          <a href="/">
            <img
              src={logoGray}
              alt="Logo Destino Docente. Encuentra destinos docentes cercanos a ti y comparte coche"
              height={57}
              width={140}
            />
          </a>
          <div className="mt-4">&copy; 2023-2024 destinodocente.es</div>
        </div>
      </Footer>
    </>
  );
};

export default PageFooter;
