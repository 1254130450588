import React, { useState, useEffect, useRef } from "react";
import { Menu, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";

const MainMenu = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    setIsMenuOpen(false);
  }, []);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div className={props.className}>
      {isMenuOpen && (
        <div ref={menuRef}>
          <Menu
            mode="vertical"
            style={{
              position: "absolute",
              left: 10,
              marginTop: props.marginTop,
              zIndex: 2000,
              width: 240,
              border: "1px solid gray",
            }}
            className="rounded"
          >
            <Menu.Item key="0" className="px-2">
              <a href="/centros">Encontrar destinos</a>
            </Menu.Item>
            <Menu.Item key="1" className="px-2">
              <a href="/compartir-coche">Compartir coche</a>
            </Menu.Item>
            <Menu.Item key="2" className="px-2">
              <a href="/compartir-casa">Compartir casa</a>
            </Menu.Item>
            <Menu.Item key="2" className="px-2">
              <a href="/mapas">Buscar destinos en el mapa</a>
            </Menu.Item>
            <Menu.Item key="3" className="px-2">
              <a href="/centros/lista">Buscar destinos en el listado</a>
            </Menu.Item>
            <Menu.Item key="4" className="px-2">
              <a href="/blog">Blog</a>
            </Menu.Item>
            <Menu.Item key="5" className="px-2">
              <a href="/noticias">Noticias</a>
            </Menu.Item>
            <Menu.Item key="6" className="px-2">
              <a href="/vacantes">Vacantes SIPRI Andalucía</a>
            </Menu.Item>
          </Menu>
        </div>
      )}
      <Button
        ref={buttonRef}
        type="button"
        icon={<MenuOutlined style={{ fontWeight: "bold" }} size={"200"} />}
        onClick={handleMenuClick}
        className="menu-button"
      />
    </div>
  );
};

export default MainMenu;
