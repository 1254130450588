import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Card, List, Skeleton, Col, Row, Image, Tag } from "antd";
import moment from "moment/moment";
import { Helmet } from "react-helmet";
import { FaBlog } from "react-icons/fa";

import PageHeader from "../../layout/header";
import PageFooter from "../../layout/footer";
import SectionImage from "../../components/visual/section-image";
import { ShareFBButton, ShareWSButton, ShareXButton } from "../../utils/utils";
import {
  API_BLOG_URL,
  trailing_key,
  CLIENT_SITE_URL,
  trailing_key_param,
} from "../../constants";
import { renderAsP } from "../portal/chunk-page";
import { firstP } from "../../utils/utils";

const { Meta } = Card;

const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const { tag: pTag } = useParams();
  //const [tag, setTag] = useState(null);

  const fetchBlogs = async () => {
    try {
      let uri = API_BLOG_URL + "/blogpost/";
      if (pTag) uri += "tag/?tag=" + pTag + trailing_key_param;
      else uri += trailing_key;
      const response = await axios.get(uri); // Suponiendo que la API REST está en esta ruta
      setBlogPosts(response.data);
    } catch (error) {
      console.error("Error fetching blog posts:", error);
    }
  };

  const renderItem = (item) => {
    return (
      <List.Item key={item.id} className="pt-0 my-4">
        <Card
          cover={
            item && item.image ? (
              <Image
                src={item.image_url}
                onError={(e) => {
                  e.target.src = (
                    <FaBlog
                      size="200px"
                      style={{
                        color: "#7EBF8E",
                      }}
                    />
                  );
                }}
                alt={item.image_title}
                style={{
                  objectFit: "cover",
                  height: "250px",
                }}
                preview={false}
              />
            ) : (
              <div
                className="py-4 text-center"
                style={{ backgroundColor: "#efefef" }}
              >
                <FaBlog
                  size="185px"
                  style={{
                    color: "#aaaaaa",
                  }}
                />
              </div>
            )
          }
        >
          <Row gutter={[8, 8]} className="mt-2">
            <Col>
              <h2>
                <a href={`/blog/${item.id}/${item.slug}`}>{item.title}</a>
              </h2>
            </Col>
            <Col md={24} xs={24}>
              {renderAsP(firstP(item.text), "blog-lead")}
              <a className="pull-right me-4 mt-2" href={`/blog/${item.id}/${item.slug}`}>
                <strong>{"Leer más"}</strong>
              </a>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col md={24} xs={24}>
              <div className="pull-right my-4 me-4">
                <i>
                  Publicado el{" "}
                  {item?.created ? moment(item?.created).format("D/M/YY") : ""}
                </i>
              </div>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col md={24} xs={24}>
              <div className="me-3">
                {Object.values(item.tags).map((tag) => (
                  <Tag color="rgba(246, 9, 9, 0.8)" className="ms-1 pull-right">
                    <a href={`/blog/${tag.name}`}>
                      <strong key={tag.id}>{tag.name}</strong>
                    </a>
                  </Tag>
                ))}
              </div>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col md={24} xs={24} className="mt-4">
              <ShareWSButton
                className="pull-right me-1"
                color="rgb(0,168,132)"
                size="32"
                message={
                  "¡Míralo en en Destino Docente! " +
                  `${item.title}` +
                  ` https://destinodocente.es/blog/${item?.id}/${item.slug}`
                }
              />
              <ShareFBButton
                className="pull-right"
                color="#1877f2"
                size="32"
                url={`https://destinodocente.es/blog/${item?.id}/${item.slug}`}
                message={`¡Míralo en Destino Docente! ${item.title} `}
              />
              <ShareXButton
                className="pull-right"
                size="32"
                url={`https://destinodocente.es/blog/${item?.id}/${item.slug}`}
                message={`¡Míralo en Destino Docente! ${item.title} `}
                hashtags={"destinodocente,SIPRI"}
              />
            </Col>
          </Row>
        </Card>
      </List.Item>
    );
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  /*
  useEffect(() => {
    if (pTag) setTag(pTag);
  }, [pTag]);

  useEffect(() => {
    fetchBlogs(tag); // lo hago asi por que no coge el state bien
  }, [tag]);
  */
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={CLIENT_SITE_URL + "/blog" + (!!pTag ? "/" + pTag : "")}
        />
        <meta
          name="description"
          content={
            "Destino Docente. Blog " + (!!pTag ? ". Etiquetado " + pTag : "")
          }
        />
        <title>{"Destino Docente. Blog " + pTag}</title>
      </Helmet>
      <PageHeader />
      <SectionImage
        title={"Blog Destino Docente " + (!!pTag ? '"' + pTag + '"' : "")}
      />
      <div className="col-md-8 offset-md-2 col-xs-12 text-page">
        {!!blogPosts ? (
          <List
            className="my-4"
            dataSource={blogPosts}
            renderItem={renderItem}
            pagination={{
              pageSize: 4,
            }}
            locale={{
              emptyText: (
                <>
                  <Card
                    cover={
                      <FaBlog
                        size="200px"
                        style={{
                          color: "#7EBF8E",
                        }}
                      />
                    }
                  >
                    <Meta
                      title={<div className="mt-4">¡Vaya! No hay posts.</div>}
                    />
                  </Card>
                </>
              ),
            }}
          />
        ) : (
          <>
            <Skeleton active />
          </>
        )}
      </div>
      <PageFooter />
    </>
  );
};

export default BlogList;
