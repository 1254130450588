import React, { useState, useEffect } from "react";
import { Tooltip } from "antd";
import { FaRegClock, FaRoad } from "react-icons/fa";

import { durationColor } from "../utils/utils";

/**
 * Componente principal que muestra o calcula las distancias y tiempos.
 * añade al marker la propiedad addDistanceToMarker, para añadir a un array de distancias
 * o bien si showinfo, pinta un componente con esta informacion
 * @param {*} props 
 * @returns 
 */
const CenterDistance = (props) => {
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [checkCounter, setCheckCounter] = useState(0);

  useEffect(() => {
    // por municipio por defecto, aino, por coords acc_enabled
    let key = `${props.munCode}-${props.idCenter}`;
    if (props.aproxCoords)
      key = `origin-${props.aproxCoords[0]},${props.aproxCoords[1]}-${props.idCenter}`;
    const checkLocalStorage = () => {
      const storedValue = localStorage.getItem(key);

      if (storedValue) {
        if (!distance && !duration) {
          const distances = JSON.parse(storedValue);
          setDistance(distances.distance.road);
          setDuration(distances.distance.travelTime);
        }
      } else {
        setCheckCounter(checkCounter + 1);
        setTimeout(checkLocalStorage, 2000);
      }
    };

    if (checkCounter < 3) {
      checkLocalStorage();
    }
  }, [props.munCode, props.idCenter, checkCounter]);

  useEffect(() => {
    // se llama desde la lista de distancias, para añadir a un array de distancias
    if (props.addDistanceToMarker)
      if (!!distance && !!duration) {
        props.addDistanceToMarker(props.marker, distance, duration);
      }
  }, [props.marker, distance, duration]);

  return (
    <>
      {/* showinfo, para componentes que no son la lista de centros, pinta directamente la info */}
      {!!props.showInfo && (
        <>
          {!!distance && (
            <Tooltip title="Distancia por carretera">
              <span className="road-distance pe-2">
                <FaRoad /> {distance}
              </span>
            </Tooltip>
          )}
          {!!duration && (
            <>
              <Tooltip title="Duración por carretera">
                <span className="road-duration px-2">
                  <FaRegClock size={16} /> <b>{duration}</b>
                </span>
              </Tooltip>

              {/* isocronas */}
              <style type="text/css">
                {".marker-" +
                  props.idCenter +
                  " {color: " +
                  durationColor(duration) +
                  "; text-shadow: 0 0 2px #26400C; }"}
              </style>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CenterDistance;
