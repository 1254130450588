import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import CentersPage from "./pages/centers-page";
import Home from "./pages/portal/home";
import Config from "./pages/portal/config";
import News from "./pages/portal/news";
import Login from "./pages/users/login-page";
import { Logout } from "./pages/users/logout";
import RegistrationPage from "./pages/users/registration-page";
import CenterInfo from "./pages/centers/center-info";
import PositionsList from "./pages/positions/position-list";
import ChunkPage from "./pages/portal/chunk-page";
import Contact from "./pages/portal/contact";
import NotFound from "./pages/not-found";
import CentersFullMap from "./pages/centers/centers-full-map";
import CentersFullList from "./pages/centers/centers-full-list";
import { PrivateRoute } from "./services/auth-services";
import PositionsFavPage from "./pages/positions/user-position-fav-list";
import { TravelSearchPage } from "./pages/travel/travel-search";
import UserMessagesPage from "./pages/messages/user-messages";
import UserTravelPage from "./pages/travel/user-travel";
import TravelInfo from "./pages/travel/travel-info";
import { AdSearchPage } from "./pages/ad/ad-search";
import UserAdsPage from "./pages/ad/user-ads";
import AdInfo from "./pages/ad/ad-info";
import CommunitySelect from "./pages/portal/community-select";
import RedirectPage from "./utils/redirect";
import BlogList from "./pages/blog/blog-list";
import BlogPost from "./pages/blog/blog-post";

const RoutesCenters = () => (
  <Router>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/centros" element={<CentersPage />} />
      <Route exact path="/centros/lista" element={<CentersFullList />} />
      <Route exact path="/centros/lista/:region/:regionName" element={<CentersFullList />} />
      <Route exact path="/centros/lista/:region/:regionName/provincia/:province/:provinceName" element={<CentersFullList />} />
      <Route exact path="/centros/lista/:region/:regionName/provincia/:province/:provinceName/denom/:centersDenom/:centersDenomName/" element={<CentersFullList />} />
      <Route exact path="/centros/lista/:region/:regionName/denom/:centersDenom/:centersDenomName/" element={<CentersFullList />} />
      <Route exact path="/centros/puesto/:positionCode/:positionName" element={<CentersPage />}/>
      <Route exact path="/centros/:codCenter" element={<CenterInfo />} />
      <Route exact path="/centros/:codCenter/:nameCenter" element={<CenterInfo />} />
      
      <Route exact path="/centros/:latitude/:longitude/:municipalityName/:municipalityCode" element={<CentersPage />} />  {/* autocomplete */}
      <Route exact path="/centros/:latitude/:longitude/:municipalityName/:municipalityCode/denom/:denom/radio/:radius" element={<NotFound />} /> {/* 404 */}
      <Route exact path="/centros/:latitude/:longitude/:municipalityName/:municipalityCode/:centersDenom/:centersDenomName/radio/:fromRadius" element={<CentersPage />} /> {/* share and near*/}
      <Route exact path="/centros/:latitude/:longitude/:municipalityName/:municipalityCode/puesto/:positionCode/:positionName" element={<CentersPage />}/>
      
      <Route exact path="/vacantes/" element={<PositionsList />} />
      <Route exact path="/vacantes/:region/:regionName" element={<PositionsList />} />
      <Route exact path="/vacantes/:region/:regionName/cuerpo/:denom" element={<PositionsList />} /> 
      <Route exact path="/vacantes/cuerpo/:denom" element={<PositionsList />} /> 
    
      <Route exact path="/mapas" element={<CentersFullMap />} />
      <Route exact path="/mapas/:region" element={<CentersFullMap />} />
      <Route exact path="/mapas/:region/:regionName" element={<CentersFullMap />} />
      <Route exact path="/mapas/puesto/:positionCode/:positionName"element={<CentersFullMap />}/>
      
      <Route exact path="/registro" element={<RegistrationPage />} />
      <Route exact path="/entrar" element={<Login />} /> 
      <Route exact path="/entrar/email" element={<Login altLogin />} />
      <Route exact path="/salir" element={<Logout />} />

      {/*  <Route exact path="/contacto-email" element={<ChunkPage chunkKey={"contact"} title={"Contacto"} />} />  */}
      <Route exact path="/fuentes-de-datos" element={<ChunkPage chunkKey={"sources"} title={"Fuentes de Datos"} path="/fuentes-de-datos" />} />
      <Route exact path="/privacidad" element={<ChunkPage chunkKey={"privacy"} title={"Política de Privacidad"} path="/privacidad"/>} />
      <Route exact path="/sobre-nosotros" element={<ChunkPage chunkKey={"about"} title={"Sobre nosotros"} path="/sobre-nosotros"/>} />
      <Route exact path="/terminos-de-uso" element={<ChunkPage chunkKey={"tos"} title={"Términos de uso"} path="/terminos-de-uso"/>} />
      <Route exact path="/redirect" element={<RedirectPage />} />
      <Route exact path="/contacto" element={<Contact />} />
      <Route exact path="/ayuda" element={<Contact />} />
      <Route exact path="/noticias" element={<News />} />

      <Route exact path="/compartir-coche" element={<TravelSearchPage />} />
      <Route exact path="/encontrar-viajes" element={<Navigate to="/compartir-coche" replace />}/>  {/* compatibilidad */}
      <Route exact path="/compartir-coche/:travelId/:travelName" element={<TravelInfo />} />
      <Route exact path="/viajes/:travelId/:travelName" element={<TravelInfo />} /> {/* compatibilidad */}

      <Route exact path="/compartir-casa" element={<AdSearchPage />} />
      <Route exact path="/compartir-casa/:adId/:adName" element={<AdInfo />} />

      <Route exact path="/blog/" element={<BlogList />} />
      <Route exact path="/blog/:tag" element={<BlogList />} />
      <Route exact path="/blog/:id/:slug" element={<BlogPost />} />

      {/* Rutas auth */}
      <Route
        path="/centros/cercanos"
        element={
          <PrivateRoute>
            <CentersPage accEnabled={true} />
          </PrivateRoute>
        }
      />
      <Route
        path="/centros/cercanos/puesto/:positionCode/:positionName"
        element={
          <PrivateRoute>
            <CentersPage accEnabled={true} />
          </PrivateRoute>
        }
      />
      <Route
        path="/tu-cuenta"
        element={
          <PrivateRoute>
            <Config />
          </PrivateRoute>
        }
      />
      <Route
        path="/tus-puestos"
        element={
          <PrivateRoute>
            <PositionsFavPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/tus-mensajes"
        element={
          <PrivateRoute>
            <UserMessagesPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/tus-viajes"
        element={
          <PrivateRoute>
            <UserTravelPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/tus-anuncios"
        element={
          <PrivateRoute>
            <UserAdsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/tus-comunidades"
        element={
          <PrivateRoute>
            <CommunitySelect />
          </PrivateRoute>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
    
  </Router>
);

export default RoutesCenters;
