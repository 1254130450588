import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaCar,
  FaRegClock,
  FaTrash,
  FaArrowDown,
  FaSchool,
  FaUniversity,
  FaWalking,
} from "react-icons/fa";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  notification,
  Input,
  Tooltip,
  Drawer,
} from "antd";
import { Radio, Space, Popup } from "antd-mobile";
import { isMobile } from "react-device-detect";
import moment from "moment/moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { NavBar } from "antd-mobile";

import MunicipalityAutocomplete from "../municipality-autocomplete";
import LoginButton from "../auth/login-button";
//import CenterAutocomplete from "../centers/center-autocomplete";
import CenterSelect from "../centers/center-select";
import { TravelPlusIcon } from "../visual/load-icon";
import {
  API_TRAVELS_URL,
  trailing_key,
  trailing_key_param,
  CEIP_DENOM,
  IES_DENOM,
} from "../../constants";
import axiosAuth from "../utils/auth-interceptor";
import { denomAbrev } from "../../constants/data";
import { getLocalUserId, isAuth } from "../../services/auth-services";

import background from "../../assets/background-travel.jpg";

const { confirm } = Modal;

const TravelButton = (props) => {
  const [form] = Form.useForm();
  let navigate = useNavigate();

  const [travel, setTravel] = useState(null);
  const [munCodeOrig, setMunCodeOrig] = useState(null);
  const [munNameOrig, setMunNameOrig] = useState(null);
  const [munCodeError, setMunCodeError] = useState("");
  const [centerCodeDest, setCenterCodeDest] = useState(null);
  const [centerNameDest, setCenterNameDest] = useState(null);
  const [centerCodeDestError, setCenterCodeDestError] = useState("");
  const [munNameDest, setMunNameDest] = useState(null);
  const [munCodeDest, setMunCodeDest] = useState(null);
  const [schedule, setSchedule] = useState(IES_DENOM);
  const [range, setRange] = useState(30); // 30 dias
  const [endDate, setEndDate] = useState(moment().add(30, "days"));
  const [travelType, setTravelType] = useState("C");
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    let uri = null;
    if (munCodeOrig && centerCodeDest)
      uri = `${API_TRAVELS_URL}/auth/retrieve-by-params/?centercode=${centerCodeDest}&muncode=${munCodeOrig}&userid=${props.userId}${trailing_key_param}`;
    else if (props.travel)
      uri = `${API_TRAVELS_URL}/${props.travel?.id}/${trailing_key}`;
    if (uri) {
      axiosAuth
        .get(uri)
        .then((response) => {
          if (response.data) {
            setTravel(response.data);
          }
        })
        .catch((error) => {
          // Verificar si el error es un error de respuesta HTTP y si el código es 404
          if (error.response && error.response.status === 404) {
            console.error("Error 404: No encontrado.");
          } else {
            console.error("Error al verificar el viaje", error);
          }
          setTravel(null);
        });
    }
  };

  const openModal = () => {
    fetchData();
    setIsModalOpened(true);
  };

  /*
  useEffect(() => {
    if (isAuth()) fetchData();
  }, [props.centerDest, props.munCodeOrig, props.userId]);
  */

  useEffect(() => {
    if (props.munCodeOrig) setMunCodeOrig(props.munCodeOrig);
  }, [props.munCodeOrig]);

  useEffect(() => {
    if (props.munNameOrig) setMunNameOrig(props.munNameOrig);
  }, [props.munNameOrig]);

  useEffect(() => {
    if (props.centerDest) setCenterCodeDest(props.centerDest.code);
  }, [props.centerDest]);

  /*
  useEffect(() => {
    if (props.travel) {
      setTravel(props.travel);
    }
  }, [props.travel]);
  */

  useEffect(() => {
    if (props.travel) setTravel(props.travel);

    if (props.travel) {
      setSchedule(props.travel.schedule);
      setRange(props.travel.range);
      setEndDate(props.travel.end_date);
      setTravelType(props.travel.travel_type);
    } else if (travel) {
      setSchedule(travel.schedule);
      setRange(travel.range);
      setEndDate(travel.end_date);
      setTravelType(travel.travel_type);
    } else {
      if (props.centerDest?.denom__id === IES_DENOM) setSchedule(IES_DENOM);
      else setSchedule(CEIP_DENOM);
      setRange(30);
      setEndDate(moment().add(30, "days"));
      setTravelType("C");
    }
  }, []);

  /*
  useEffect(() => {
    if (props.travel) {
      setTravel(props.travel);
      setMunCodeOrig(props.travel.municipality_from?.code);
      setCenterCodeDest(props.travel.center_to?.code);
      setSchedule(props.travel.schedule || IES_DENOM);
      setRange(props.travel.range || 30);
      setEndDate(props.travel.end_date);
      setTravelType(props.travel.travel_type || "C");
    } else {
      // Restablece el estado si props.travel es null o undefined
      resetState();
    }
  }, [props.travel]); // Dependencia: props.travel
*/
  // Función para restablecer el estado a valores predeterminados o basados en otras props si es necesario
  const resetState = () => {
    setTravel(null);
    setMunCodeOrig(null);
    setMunNameOrig(null);
    setCenterCodeDest(null);
    setCenterNameDest(null);
    setMunCodeDest(null);
    setMunNameDest(null);
    setSchedule(CEIP_DENOM); // O cualquier otro valor predeterminado relevante
    setRange(30);
    setEndDate(moment().add(30, "days"));
    setTravelType("C");
    setMunCodeError("");
    setCenterCodeDestError("");
  };

  const onChangeSchedule = (value) => {
    setSchedule(value);
  };

  const onChangeTravel = (value) => {
    setTravelType(value);
  };

  const onChangeRange = (value) => {
    // calcular las fecha de inicio y fin segun los dias seleccionados
    setRange(value);
    let currentDate = moment();
    let valueDaysAdded = moment(currentDate).add(value, "days"); // Clona y añade días
    let endOfSchoolYear;

    // Determina el fin de curso actual o próximo
    if (currentDate.isAfter(moment(`${currentDate.year()}-06-30`))) {
      // Si ya pasamos el fin del curso este año, calcula para el próximo año
      endOfSchoolYear = moment(`${currentDate.year() + 1}-06-30`);
    } else {
      // Si aún no hemos llegado al fin del curso este año
      endOfSchoolYear = moment(`${currentDate.year()}-06-30`);
    }

    let maxDate = valueDaysAdded.isBefore(endOfSchoolYear)
      ? valueDaysAdded
      : endOfSchoolYear;

    setEndDate(maxDate.format("YYYY-MM-DDThh:mm:ss"));
  };

  const onSelectMunicipalities = async (value, item) => {
    if (value !== undefined) {
      const children = item.label.props.children;
      const code = children[2].props.children;
      setMunCodeOrig(code); // munCode
      setMunNameOrig(value);
      setMunCodeError("");
    }
  };

  const onSelectMunicipalitiesDest = async (value, item) => {
    if (value !== undefined) {
      const children = item.label.props.children;
      const code = children[2].props.children;
      setMunCodeDest(code); // munCode
      setMunNameDest(value);
    }
  };

  const onClearMunicipalities = () => {
    setMunCodeOrig(null);
    setMunNameOrig(null);
    setMunCodeError("");
  };

  const onClearMunicipalitiesDest = () => {
    setMunCodeDest(null);
    setMunNameDest(null);
  };

  const onSelectCenters = async (value, item) => {
    if (value !== undefined) {
      const children = item.label.props.children;
      const code = children[2].props.children;
      setCenterCodeDest(code);
      setCenterNameDest(value);
      setCenterCodeDestError("");
    }
  };

  const onClearCenters = () => {
    setCenterCodeDest(null);
    setCenterNameDest(null);
    setCenterCodeDestError("");
  };

  const handleSubmit = async () => {
    setLoading(true);
    setMunCodeError("");
    setCenterCodeDestError("");
    let uri;
    let errorMessages = [];
    //const formData = new FormData();
    if (munCodeOrig == null) {
      errorMessages.push("Indica un municipio de origen");
      setMunCodeError("Indica un municipio de origen");
    }
    if (centerCodeDest == null) {
      errorMessages.push("Indica un centro de destino");
      setCenterCodeDestError("Indica un centro de destino");
    }
    if (errorMessages.length > 0) {
      setLoading(false);
      return;
    }

    try {
      const params = {
        travelId: travel?.id,
        munCode: munCodeOrig,
        centerCode: centerCodeDest,
        schedule: schedule,
        range: range,
        travelType: travelType,
        startDate: moment().format("YYYY-MM-DDThh:mm:ss"),
        endDate: endDate,
        roadDuration:
          props.roadDuration &&
          Number(props.roadDuration?.replace("km", "").replace(/[^\d.-]/g, "")),
      };

      if (travel?.id)
        uri = `${API_TRAVELS_URL}/auth/update-by-params/${trailing_key}`;
      else uri = `${API_TRAVELS_URL}/auth/create-by-params/${trailing_key}`;
      const response = await axiosAuth.post(uri, params);
      if (response)
        notification.success({
          message: "El viaje se ha guardado correctamente",
        });
      //setTravel(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        const stateObj = { state: { from: window.location.pathname } };
        navigate("/salir", stateObj);
      } else {
        notification.warning({
          message: "Error al guardar el viaje",
        });
      }

      console.error(error);
    } finally {
      if (props) {
        if (typeof props.afterFinish === "function") {
          try {
            props.afterFinish();
          } catch (error) {
            console.error("afterFinish not defined", error);
          }
        }
      }
      resetState();
      setLoading(false);
      setIsModalOpened(false);
    }
  };

  const onClickDelete = () => {
    confirm({
      title: "¿Quieres eliminar este viaje?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      onOk: () => handleDelete(),
      onCancel: () => Modal.destroyAll(),
      footer: [
        <Button onClick={() => Modal.destroyAll()}>Cancelar</Button>,
        <Button onClick={handleDelete} className="danger ms-2" danger>
          Aceptar
        </Button>,
      ],
    });
  };

  const handleDelete = async () => {
    try {
      const uri = `${API_TRAVELS_URL}/${travel.id}/${trailing_key}`;

      const response = await axiosAuth.delete(uri);
      if (response) {
        setTravel(null);
        notification.success({
          message: "El viaje se ha eliminado correctamente",
          description: "",
        });
        Modal.destroyAll();
      }
    } catch (error) {
      if (error.response.status === 401) {
        const stateObj = { state: { from: window.location.pathname } };
        navigate("/salir", stateObj);
      } else {
        notification.warning({
          message: "Error al eliminar el viaje",
        });
      }
    }
  };

  const TravelFormPopUp = () => {
    return (
      <Form
        form={form}
        /*
        fields={[
          {
            name: ["schedule"],
            value: !!travel?.schedule ? !!travel?.schedule : undefined,
          },
          {
            name: ["travel"],
            value: !!travel?.travelType ? travel.travelType : undefined,
          },
          {
            name: ["range"],
            value: !!travel?.range ? travel.range : undefined,
          },
          {
            name: ["munCodeOrig"],
            value: !!travel?.municipality_from
              ? travel.municipality_from.code
              : undefined,
          },
          {
            name: ["centerCodeDest"],
            value: !!travel?.center_to ? travel.center_to.code : undefined,
          },
        ]}*/
        initialValues={{
          ["schedule"]: schedule,
          ["travel"]: travelType,
          ["range"]: range,
          ["munCodeOrig"]: props.munNameOrig ? props.munNameOrig : null,
          ["centerCodeDest"]:
            `${
              denomAbrev[props.centerDest?.denom__name]
                ? denomAbrev[props.centerDest?.denom__name]
                : ""
            }` +
            ` "${props.centerDest?.name}" de ${props.centerDest?.city} (${props.centerDest?.province})`,
          ["munCodeDest"]: munCodeDest,
        }}
      >
        <Row>
          <Col>
            <h5 className="my-4">¿Cuál es tu horario?</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item name="schedule">
              <Radio.Group onChange={onChangeSchedule} value={schedule}>
                <Space direction="vertical" style={{ display: "flex" }}>
                  <Radio value={CEIP_DENOM}>
                    <FaSchool className="mx-2" style={{ color: "LimeGreen" }} />
                    <span className="mx-2" style={{ color: "#000" }}>
                      Horario de Colegio
                    </span>
                  </Radio>
                  <Radio value={IES_DENOM}>
                    <FaUniversity
                      className="mx-2"
                      style={{ color: "darkred" }}
                    />
                    <span className="mx-2" style={{ color: "#000" }}>
                      Horario de Instituto
                    </span>
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <h5 className="mb-4">Indica el trayecto</h5>
          </Col>
        </Row>
        <Row>
          <Col md={24} xs={24}>
            <Form.Item
              name="munCodeOrig"
              validateStatus={munCodeError ? "error" : ""}
              help={munCodeError || ""}
            >
              {!props.munCodeOrig ? (
                <MunicipalityAutocomplete
                  autoFocus={false}
                  onSelectMunicipalities={onSelectMunicipalities}
                  fullOnclick={true}
                  onClearMunicipalities={onClearMunicipalities}
                  defaultValue={munNameOrig}
                  placeHolder="Escribe el municipio de origen"
                />
              ) : (
                <Input value={props.munNameOrig} readOnly />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[0, 0]}>
          <Col md={24} xs={24} className="text-center">
            <Form.Item>
              <center>
                <FaArrowDown size={"24px"} />{" "}
                {props.roadDuration && (
                  <span className="ms-2" style={{ fontSize: "1rem" }}>
                    <FaRegClock /> {props.roadDuration}
                  </span>
                )}
              </center>
            </Form.Item>
          </Col>
        </Row>

        <Row
          className="rounded px-2 pt-4 mb-4"
          style={{ border: "2px solid #7ebf8e" }}
        >
          <Col md={24} xs={24}>
            {!props.centerDest && (
              <Form.Item name="munCodeDest">
                <MunicipalityAutocomplete
                  autoFocus={false}
                  onSelectMunicipalities={onSelectMunicipalitiesDest}
                  fullOnclick={true}
                  onClearMunicipalities={onClearMunicipalitiesDest}
                  placeHolder="Escribe el municipio para filtrar centros"
                  defaultValue={munNameDest}
                />
              </Form.Item>
            )}
          </Col>

          <Col md={24} xs={24}>
            <Form.Item
              name="centerCodeDest"
              validateStatus={centerCodeDestError ? "error" : ""}
              help={centerCodeDestError || ""}
            >
              {!props.centerDest ? (
                <>
                  {/*
                  <CenterAutocomplete
                    denom={schedule}
                    munCode={munCodeDest}
                    fullOnclick={true}
                    defaultValue={centerNameDest}
                    disabled={!munCodeDest}
                    placeHolder="Escribe el centro de destino"
                    onSelectCenters={onSelectCenters}
                    onClearCenters={onClearCenters}
                  />
                  */}
                  <CenterSelect
                    munCode={munCodeDest}
                    denom={schedule}
                    defaultValue={centerNameDest}
                    disabled={!munCodeDest}
                    autoFocus={false}
                    fullOnclick={true}
                    placeHolder="Seleciona el centro de destino"
                    onSelectCenters={onSelectCenters}
                    onClearCenters={onClearCenters}
                  />
                </>
              ) : (
                <Input
                  value={`${
                    denomAbrev[props.centerDest.denom__name]
                      ? denomAbrev[props.centerDest.denom__name]
                      : ""
                  } "${props.centerDest?.name}" de ${props.centerDest?.city} (${
                    props.centerDest?.province
                  })`}
                  readOnly
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <h5 className="mb-4">¿Tienes coche?</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item
              name="travel"
              rules={[
                {
                  required: true,
                  message: "Por favor selecciona si tienes o necesitas coche",
                },
              ]}
            >
              <Radio.Group onChange={onChangeTravel} value={travelType}>
                <Space direction="vertical" style={{ display: "flex" }}>
                  <Radio value="C">
                    <FaCar className="mx-2" style={{ color: "LimeGreen" }} />
                    <span className="mx-2" style={{ color: "#000" }}>
                      Tengo coche
                    </span>
                  </Radio>
                  <Radio value="N">
                    <FaWalking className="mx-2" style={{ color: "darkred" }} />
                    <span className="mx-2" style={{ color: "#000" }}>
                      No tengo coche
                    </span>
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col>
            <h5 className="mb-4">¿Cuánto tiempo harás el viaje?</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item name="range">
              <Radio.Group onChange={onChangeRange} value={range}>
                <Space direction="vertical" style={{ display: "flex" }}>
                  <Radio value={30}>Hasta 1 mes</Radio>
                  <Radio value={90}>Hasta 3 meses</Radio>
                  <Radio value={270}>Todo el curso</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[8, 8]}>
          <Col md={24}>
            {!!isAuth() && travel?.creator?.id == getLocalUserId() && (
              <Button className="danger" danger onClick={onClickDelete}>
                <FaTrash className="me-2" /> Eliminar
              </Button>
            )}
            <Button
              key="back"
              onClick={() => {
                setIsModalOpened(false);
                resetState();
              }}
              className="me-2 d-none d-sm-inline"
            >
              Cancelar
            </Button>
            <Button
              className="button d-xs-block"
              htmlType="submit"
              loading={loading}
              onClick={handleSubmit}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <>
      {!!isAuth() ? (
        <>
          <Tooltip title={props.title}>
            <Button
              type="button"
              className={props.className ? props.className : "btn-action-plus"}
              onClick={() => {
                openModal();
              }}
              style={{ paddingRight: "11px" }}
              icon={<TravelPlusIcon iconSize={props.iconSize} />}
            >
              {!!props.children && props.children}
              {props.text}
            </Button>
          </Tooltip>
          <Drawer
            open={isModalOpened && !isMobile}
            footer={null}
            onClose={() => {
              setIsModalOpened(false);
              resetState();
            }}
            width={"33%"}
            title={!!travel ? "Actualizar viaje" : "Ahorra con tu viaje"}
          >
            <TravelFormPopUp />
          </Drawer>

          <Popup
            visible={isModalOpened && isMobile}
            getContainer={false} // para que funcione en mobile
            onMaskClick={() => {
              setIsModalOpened(false);
              resetState();
            }}
            position="right"
            bodyStyle={{ width: "100vw" }}
          >
            <NavBar
              onBack={() => {
                setIsModalOpened(false);
                resetState();
              }}
              className="back-bar"
              style={{ height: "64px" }}
            >
              {!!travel ? "Actualiza " : "Ahorra con tu "}viaje
            </NavBar>
            <div className="px-4 overflow">
              <TravelFormPopUp />
              <br />
              <br />
              <br />
              <br />
            </div>
          </Popup>
        </>
      ) : (
        <>
          <Button
            type="button"
            className={props.className ? props.className : "btn-action-plus"}
            onClick={() => {
              setIsModalOpened(true);
            }}
            style={{ paddingRight: "11px" }}
            icon={<TravelPlusIcon iconSize={props.iconSize} />}
          >
            {!!props.children && props.children}
            {props.text}
          </Button>

          <Modal
            open={isModalOpened && !isMobile}
            footer={null}
            onCancel={() => {
              setIsModalOpened(false);
              resetState();
            }}
          >
            <h4 className="mb-3">Publica un viaje</h4>
            <img
              alt="Viajes Destino Docente"
              src={background}
              style={{ maxWidth: "100%" }}
            />
            <p className="lead mt-4">
              ¿Quieres compartir coche? ¿Necesitas coche para ir a tu centro?
            </p>
            <p>
              Inicia sesión y publica tu viaje. Podrás contactar con otros
              docentes con el mismo trayecto que tú.
            </p>
            <div className="text-center">
              <LoginButton from={window.location.pathname} className={"button"}>
                ¡Vamos!
              </LoginButton>
            </div>
          </Modal>

          <Popup
            visible={isModalOpened && isMobile}
            getContainer={false} // para que funcione en mobile
            onMaskClick={() => {
              setIsModalOpened(false);
              resetState();
            }}
            bodyStyle={{ height: "75vh" }}
          >
            <img
              alt="Viajes Destino Docente"
              src={background}
              style={{ width: "100%" }}
            />
            <div className="p-2">
              <h4 className="mt-1">Publica un viaje</h4>
              <p>
                <b>
                  ¿Quieres compartir coche? ¿Necesitas coche para ir a tu
                  centro?
                </b>
                <br />
                Inicia sesión y publica tu viaje. Podrás contactar con otros
                docentes con el mismo trayecto que tú.
              </p>
              <LoginButton from={window.location.pathname} className={"button"}>
                ¡Vamos!
              </LoginButton>
            </div>
          </Popup>
        </>
      )}
    </>
  );
};

export default TravelButton;
