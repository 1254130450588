import React, { useState, useEffect } from "react";
import { List, Card } from "antd";
import axios from "axios";
import { renderItem } from "../../pages/travel/travel-search";
import { api_key, API_TRAVELS_URL } from "../../constants";
import { buildQueryString } from "../../utils/utils";
import { getLocalUserId } from "../../services/auth-services";
import TravelButton from "./travel-button";

const TravelsMunCenter = (props) => {
  const [travels, setTravels] = useState([]);
  const { Meta } = Card;

  const fetchDataTravels = async (munCodeOrig, munCodeDest, schedule) => {
    let params = [];
    if (munCodeOrig) {
      params = [
        { key: "muncode", value: munCodeOrig },
        { key: "muncodedest", value: munCodeDest },
        //  { key: "schedule", value: schedule },
        { key: "api_key", value: api_key },
      ];
    } else {
      params = [
        { key: "muncodedest", value: munCodeDest },
        //  { key: "schedule", value: schedule },
        { key: "api_key", value: api_key },
      ];
    }

    const queryString = buildQueryString(params);
    const uri = `${API_TRAVELS_URL}/search-travels/?${queryString}`;

    try {
      const response = await axios.get(uri);
      if (response.data) {
        setTravels(response.data);
      }
    } catch (error) {
      console.error("Hubo un error recuperar los datos", error);
    }
  };

  useEffect(() => {
    fetchDataTravels(props.munCodeOrig, props.munCodeDest, props.schedule);
  }, [props.munCodeOrig, props.munCodeDest, props.schedule]);

  return (
    <>
      <h5 className="my-2">¿Compartes coche hasta aquí?</h5>
      <>
        <List
          dataSource={travels}
          renderItem={renderItem}
          locale={{
            emptyText: (
              <>
                <Card>
                  <Meta
                    title={"¿No encuentras viaje?"}
                    //description={}
                  />
                  <div className="mt-4">
                    <TravelButton
                      munCodeOrig={props.munCodeOrig}
                      munNameOrig={props.munNameOrig}
                      centerDest={props.centerDest}
                      userId={getLocalUserId()}
                      title="Publica un viaje a este centro"
                      text={
                        "Publica tu viaje" +
                        (props.munNameOrig ? " de " + props.munNameOrig : "") +
                        " a " +
                        props.centerDest?.name
                      }
                      afterFinish={() => {
                        fetchDataTravels(
                          props.munCodeOrig,
                          props.munCodeDest,
                          props.schedule
                        );
                      }}
                    />
                    <br />
                    <br /> o ve al{" "}
                    <a href="/compartir-coche">
                      <span className="underlined">buscador de viajes.</span>
                    </a>
                  </div>
                </Card>
              </>
            ),
          }}
        />
      </>
      {!!travels && travels.length > 0 && (
        <div className="my-4">
          <span className="me-3 blog-lead">
            ¿No hay viajes convenientes para tí? Publica tu viaje a este centro
          </span>
          <TravelButton
            munCodeOrig={props.munCodeOrig}
            munNameOrig={props.munNameOrig}
            centerDest={props.centerDest}
            userId={getLocalUserId()}
            iconSize={24}
            title="Publica un viaje a este centro"
            className=""
            afterFinish={() => {
              fetchDataTravels(
                props.munCodeOrig,
                props.munCodeDest,
                props.schedule
              );
            }}
          />
        </div>
      )}
    </>
  );
};

export default TravelsMunCenter;
