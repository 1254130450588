import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaPaperPlane } from "react-icons/fa";
import { Button, Drawer, List, Skeleton, Card, notification } from "antd";
import { Popup, NavBar } from "antd-mobile";
import { isMobile } from "react-device-detect";
import moment from "moment";

import SendMessageForm from "./message-form";
import LoginButton from "../auth/login-button";
import { fetchConversation } from "./messaging-services";
import { isAuth } from "../../services/auth-services";
import { getLocalUserId } from "../../services/auth-services";
import { delay } from "../../utils/utils";

const MessageButton = (props) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [messages, setMessages] = useState([]);
  const listRef = useRef(null);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const fetchMessagesAsync = async (senderId, recipientId) => {
    try {
      const fetchedMessages = await fetchConversation(senderId, recipientId);
      return fetchedMessages;
    } catch (error) {
      if (error.response.status === 401) {
        const stateObj = {
          state: {
            from: window.location.pathname,
            message: "Tienes que iniciar de nuevo la sesión",
          },
        };
        navigate("/salir", stateObj);
      } else {
        notification.warning({
          message: "Se ha producido un error",
        });
        throw error;
      }
    }
  };

  const afterMessageSent = async () => {
    const fetchedMessages = await fetchMessagesAsync(
      props.senderId,
      props.recipientId
    );
    await delay(500); // Simula un retraso
    setMessages(fetchedMessages);
  };

  useEffect(() => {
    if (isModalOpened) {
      const loadMessages = async () => {
        setLoading(true);
        try {
          const fetchedMessages = await fetchMessagesAsync(
            props.senderId,
            props.recipientId
          );
          setMessages(fetchedMessages);
        } catch {
          setMessages([]);
        } finally {
          setLoading(false);
        }
      };

      loadMessages();
    }
  }, [isModalOpened, props.senderId, props.recipientId]); // Incluyendo dependencias para evitar efectos innecesarios

  useEffect(() => {
    // Scroll hacia abajo al cargar o actualizar la lista
    if (listRef.current) {
      const listElement = listRef.current;
      listElement.scrollTop = listElement.scrollHeight;
    }
  }, [messages]);

  return (
    <>
      {!!isAuth() ? (
        <Button
          type="button"
          className={props.className ? props.className : "btn-action"}
          onClick={async () => {
            setIsModalOpened(true);
            await afterMessageSent();
          }}
        >
          <FaPaperPlane />
          {!!props.children && <span>{props.children}</span>}
        </Button>
      ) : (
        <>
          <LoginButton
            from={window.location.pathname}
            className={props.className ? props.className : "btn-action"}
          >
            <FaPaperPlane /> Contactar
          </LoginButton>
        </>
      )}

      <Drawer
        open={isModalOpened && !isMobile}
        footer={null}
        onClose={() => {
          setMessages([]);
          setIsModalOpened(false);
        }}
        width={"41.666666%"}
        title={!!props.children && <>{props.children} </>}
      >
        <div className="m-0 p-0" style={{ backgroundColor: "" }}>
          <div
            ref={listRef}
            className="overflow mt-0 message-list rounded"
            style={{ height: "55vh" }}
          >
            <List
              className="conversation"
              itemLayout="horizontal"
              dataSource={messages}
              locale={{
                emptyText: "No hay mensajes que mostrar",
              }}
              renderItem={(item) => (
                <List.Item
                  className={`message ${
                    item.sender == getLocalUserId() ? "sent" : "received"
                  }`}
                >
                  <Skeleton avatar title={false} loading={loading} active>
                    <List.Item.Meta
                      description={
                        <>
                          <div>{item.content}</div>{" "}
                          <div style={{ textAlign: "right" }}>
                            <small>
                              {moment(item.timestamp).format("D/M/YY hh:mm")}
                            </small>
                          </div>
                        </>
                      }
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </div>
          <Card style={{ border: "2px solid #7ebf8e" }} className="mt-4 pt-4">
            <SendMessageForm
              senderId={props.senderId}
              recipientId={props.recipientId}
              afterFinish={() => {
                afterMessageSent();
              }}
              afterCancel={() => {
                setMessages([]);
                setIsModalOpened(false);
              }}
            />
          </Card>
        </div>
      </Drawer>

      <Popup
        visible={isModalOpened && isMobile}
        getContainer={false} // para que funcione en mobile
        onMaskClick={() => {
          setMessages([]);
          setIsModalOpened(false);
        }}
        onCancel={() => {
          setMessages([]);
          setIsModalOpened(false);
        }}
        bodyStyle={{ width: "100%" }}
        position="right"
      >
        <>
          <NavBar
            onBack={() => {
              setIsModalOpened(false);
              setIsModalOpened(false);
            }}
            className="back-bar"
            style={{ height: "64px" }}
          >
            {props.children}
          </NavBar>
          <div
            ref={listRef}
            className="overflow message-list"
            style={{ maxHeight: "65vh" }}
          >
            <List
              className="conversation"
              itemLayout="horizontal"
              dataSource={messages}
              locale={{
                emptyText: "No hay mensajes que mostrar",
              }}
              renderItem={(item) => (
                <List.Item
                  className={`message ${
                    item.sender == getLocalUserId() ? "sent" : "received"
                  }`}
                >
                  <Skeleton avatar title={false} loading={loading} active>
                    <List.Item.Meta
                      description={
                        <>
                          <div>{item.content}</div>{" "}
                          <div style={{ textAlign: "right" }}>
                            <small>
                              {moment(item.timestamp).format("D/M/YY hh:mm")}
                            </small>
                          </div>
                        </>
                      }
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </div>
          <Card className="mt-4 pt-4">
            <SendMessageForm
              senderId={props.senderId}
              recipientId={props.recipientId}
              afterFinish={() => {
                afterMessageSent();
              }}
              afterCancel={() => {
                setMessages([]);
                setIsModalOpened(false);
              }}
            />
          </Card>
        </>
      </Popup>
    </>
  );
};

export default MessageButton;
