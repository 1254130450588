import React, { useEffect, useRef, useState } from "react";

import { renderToStaticMarkup } from "react-dom/server";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import { divIcon } from "leaflet";
import "leaflet/dist/leaflet.css";

const iconMarkup = renderToStaticMarkup(
  <i className="fa fa-map-marker fa-2x center-marker" aria-hidden="true" />
);

const markerIcon = divIcon({
  html: iconMarkup,
});

function CenterMap(props) {
  const mapRef = useRef(null);
  const [zoom, setZoom] = useState(13);

  // fit to markers radius
  useEffect(() => {
    if (!!props.coords && mapRef.current) {
      //const bounds = L.latLngBounds([[props.coords[0], props.coords[1]]]);
      const centerLatLng = L.latLng(props.coords);
      const bounds = centerLatLng.toBounds(150);
      mapRef.current.fitBounds(bounds);
    }
  }, [props.coords, mapRef]);
  //<Recenter coords={props.coords} radius={25} />
  //
  return (
    <>
      {!!props.coords && (
        <MapContainer
          ref={mapRef}
          center={props.coords}
          style={{
            height: props.height ? props.height : "100%",
            width: "100%",
          }}
          scrollWheelZoom={false}
          zoomControl={false}
          zoom={zoom}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker
            position={props.coords}
            key={"centermark"}
            icon={markerIcon}
          />
        </MapContainer>
      )}
    </>
  );
}
//});

export default CenterMap;
