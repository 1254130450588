import React from "react";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Card } from "antd-mobile";

import GoogleLoginComponent from "../../components/auth/google-login";
import LoginForm from "../../forms/login-form";
import { REDIRECT_URL } from "../../services/auth-services";
import { renderAsDiv } from "../portal/chunk-page";
import { getChunkByKey } from "../../services/utils-services";

import logo from "../../assets/logo.png";

function Login(props) {
  const location = useLocation();
  const [fromParams] = useSearchParams();

  const [from, setFrom] = useState("/");
  const [text, setText] = useState("");
  const [msg, setMsg] = useState(null);

  const welcomeMsg = (
    <>
      <div style={{ textAlign: "left" }}>
        Para continuar es necesario <b>iniciar sesión</b>. Si no te habías
        registrado, al hacerlo podrás:
        <br />
        <br />
        <ul className="ms-2">
          <li>Encontrar centros más cercanos a tí</li>
          <li>Publicar viajes y compartir coche</li>
          <li>Publicar anuncios</li>
          <li>Ver las rutas a tu centro en Google Maps</li>
          <li>Enviar mensajes a otros usuarios</li>
        </ul>
        Regístrate en <b>destinodocente.es</b>, es{" "}
        <b>gratis</b> y puedes darte de baja cuando quieras.
      </div>
    </>
  );

  useEffect(() => {
    document.title = "Destino Docente. Inicio de sesión";
    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };
    fetchDataChunk("login");
    setMsg(welcomeMsg);

  }, []);

  // si recibo por url GET
  useEffect(() => {
    setFrom(fromParams.get("from"));
  }, [fromParams]);

  // si recibo por state
  useEffect(() => {
    if (location.state && location.state.from) {
      setFrom(
        location.state.from.pathname
          ? location.state.from.pathname
          : location.state.from
          ? location.state.from
          : REDIRECT_URL
      );
    }
  }, [location.state]);

  return (
    <div className="col-xs-12 jumbotron-wrapper" style={{ height: "100vh" }}>
      {/*!!msg && (
        <div
          style={{ position: "absolute", top: "0", left: "0", width: "100vw" }}
        >
          <NoticeBar content={msg} color="error" className="text-center" />
        </div>
      )*/}
      <div className="container">
        <div className="jumbotron">
          <div className="jumbotron-inner rounded">
            <div>
              <a href="/">
                <img
                  src={logo}
                  className="img img-responsive inner-logo mt-4"
                  alt="Destino Docente logo"
                />
              </a>
            </div>

            <div className="row mt-0">
              <div className="col-xs-12">
                {/*
                <p className="px-2">
                  Puedes
                  <a href="/registro">
                    <b>registrarte gratis</b>
                  </a>
                </p>
                */}
                {renderAsDiv(text)}
              </div>
              <div className="col-xs-12">
                {!!props.altLogin && (
                  <div className="px-2 m-2">
                    <LoginForm from={from} />
                    <small>
                      ¿Problemas para acceder? Contacta{" "}
                      <a href="/contacto">
                        <b>aquí</b>
                      </a>
                    </small>
                  </div>
                )}
                <center>
                  {!!msg && (
                    <Card className="px-2 py-0 col-xs-12 col-md-8">{msg}</Card>
                  )}
                  <div className="my-3">Accede con tu cuenta Google.</div>
                  <GoogleLoginComponent from={from} />
                </center>
              </div>
              <div className="my-3 px-3 small">
                <hr />
                Al seguir utilizando destinodocente.es, aceptas los{" "}
                <a href="/terminos-de-uso">Términos de uso</a> y la{" "}
                <a href="/privacidad">Política de privacidad</a>.
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
}

export default Login;
